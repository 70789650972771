<template>
    <div class="home__container">
        <div class="home__title">New York wage dispute data.<br>How do you want to search?asd</div>
        <v-card class="filter-card">
            <v-card-text>
                <v-text-field class="password" v-model="password" label="Password" :error="error" width="256" outlined dense clearable prepend-inner-icon="mdi-magnify" @keydown.enter="onPasswordConfirm" />
                <v-btn color="green" dark class="button" @click="onPasswordConfirm">Confirm</v-btn>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data () {
        return {
            password: '',
            error: false,
        }
    },
    methods: {
        onPasswordConfirm () {
            this.error = false
            if (this.password === atob('ZG9jdW1lbnRlZG55d2FnZXM')) {
                localStorage.setItem('user-password', this.password)
                this.$router.push('/')
            } else {
                this.error = true
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/shared/styles/common';

.home {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        background-image: url('../assets/nyc.jpeg');
        background-position: center;
        background-size: cover;
    }
    &__title {
        @include fat-font;
        color: white;
        margin: 124px 0;
        font-size: 40px;
        text-align: center;
    }
}
.filter-card {
    padding: 24px;
}
.search-filter {
    padding: 34px 96px;
}
.button {
    margin-left: auto;
    display: block;
}
.password {
    width: 256px;
}
</style>
